import React from "react";
// import TeamCard from "./teamCard";
import withWaypoint from "../../common/withWaypoint";

function Team() {
  return (
    <div className="section team-container" id="team">
      <div
        className={"component-heading purple-gradient-heading colored-txt-blue"}
      >
        {/* OUR TEAM */}
      </div>
      {/* <TeamCard /> */}
    </div>
  );
}
export default Team;

//export default withWaypoint(Team);

type Props = {
  fade: any;
};
