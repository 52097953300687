import { useState, useEffect } from "react";
import { API_URL } from "../common";
import { stakingContract } from "../contracts/staking_contract";
import { log } from "console";
const axios = require("axios");

export const useData = () => {
  const [secretApy, setSecretApy] = useState("");
  const [junoTvl, setJunoTvl] = useState("");
  const [junoApy, setJunoApy] = useState("");
  const [secretTvl, setSecretTvl] = useState("");
  const [secretPriceUsd, setSecretPriceUsd] = useState("0.95106");
  const [junoPriceUsd, setJunoPriceUsd] = useState("0.95106");
  const [archTvl, setArchTvl] = useState("");
  const [archApy, setArchApy] = useState("");
  const [archPriceUsd, setArchPriceUsd] = useState("");

  useEffect(() => {
    fetchInfo();
    fetchJunoTvl();
    fetchApy();
    fetchJunoApy();
    fetchJunoPriceUsd();
    fetchSecretPrice();
  }, [secretPriceUsd, junoPriceUsd]);

  async function fetchInfo() {
    try {
      setSecretTvl("100536.995");
    } catch (error) {
      console.log(error);
      return;
    }
  }

  async function fetchJunoTvl() {
    try {
      setJunoTvl("34404.317");
    } catch (error) {
      console.log(error);
      return;
    }
  }

  const fetchSecretPrice = async () => {
    let fetchDatadis = await fetch(
      `https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=${"secret"}&order=market_cap_desc&per_page=100&page=1&sparkline=false`
    );

    const data_dis = await fetchDatadis.json();
    //  const data_dis= await CoinGeckoClient.coins.fetchMarketChart(`${match.params.id}`);
    console.log("added");
    console.log(data_dis);
    console.log(data_dis[0].current_price);

    setSecretPriceUsd(data_dis[0].current_price);
  };

  const fetchJunoPriceUsd = async () => {
    let fetchDatadis = await fetch(
      `https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=${"juno-network"}&order=market_cap_desc&per_page=100&page=1&sparkline=false`
    );

    const data_dis = await fetchDatadis.json();
    //  const data_dis= await CoinGeckoClient.coins.fetchMarketChart(`${match.params.id}`);
    console.log("added");
    console.log(data_dis);
    console.log(data_dis[0].current_price);
    setJunoPriceUsd(data_dis[0].current_price);
    // setSecretPriceUsd(data_dis.current_price);
  };

  async function fetchApy() {
    try {
      setSecretApy("23.781");
    } catch (error) {
      console.log(error);
      return;
    }

    // setIsLoading(false);
  }

  async function fetchJunoApy() {
    try {
      setJunoApy("50.555");
    } catch (error) {
      console.log(error);
      return;
    }

    // setIsLoading(false);
  }

  const fetchArchwayTvl = async () => {
    try {
      setArchTvl("-");
    } catch (error) {
      console.log(error);
      return;
    }
  };

  const fetchArchwayApy = async () => {
    try {
      setArchApy("-");
    } catch (error) {
      console.log(error);
      return;
    }
  };

  const fetchArchPriceUsd = async () => {
    try {
      let fetchDatadis = await fetch(
        `https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=${"archway-network"}&order=market_cap_desc&per_page=100&page=1&sparkline=false`
      );

      const data_dis = await fetchDatadis.json();
      //  const data_dis= await CoinGeckoClient.coins.fetchMarketChart(`${match.params.id}`);
      console.log("added");
      console.log(data_dis);
      console.log(data_dis[0].current_price);
      setArchPriceUsd(data_dis[0].current_price);
    } catch (error) {
      console.log(error);
    }
  };

  return {
    secretApy,
    secretTvl,
    secretPriceUsd,
    junoPriceUsd,
    junoApy,
    junoTvl,
    archApy,
    archTvl,
    archPriceUsd,
  };
};
