type SingleValidatorProps = {
	validatorItem: any;
	link: string;
	validatorTitle: string;
};

const SingleValidator = ({
	validatorItem,
	link,
	validatorTitle,
}: SingleValidatorProps) => {
	return (
		<div className='validator__item'>
			<a href={link} target='_blank'>
				<img
					src={validatorItem}
					alt='validatorItem'
					className='validator__item-img'
				/>
			</a>
			<h3>{validatorTitle}</h3>
		</div>
	);
};

export default SingleValidator;
