import React from "react";
import { socialMediaMap } from "../../common";
import "./footer.css";
import withWaypoint from "../../common/withWaypoint";

function Footer() {
  return (
    <div className="section footer-container">
      <div className="company-name">
        © {new Date().getFullYear()} Arufa Reserach
      </div>
      <div className="follow-links">
        {socialMediaMap.map((social) => (
          <a href={social[1]} target="_blank">
            <i className={`fab fa-${social[0]}`}></i>
          </a>
        ))}
      </div>
    </div>
  );
}

export default Footer;
