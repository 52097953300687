import React, { useContext } from "react";
import AppCard from "./app-card/AppCard";
import scrtLogo from "../../common/images/scrt.png";
import junoLogo from "../../common/images/juno.png";
import archLogo from "../../common/images/Archway.png";
import elrondLogo from "../../common/images/elrond-logo-only.png";
import osmosis from "../../common/images/osmosis-logo.png";
import cosmos from "../../common/images/cosmos-hub-logo.png";
import "./AppCarousel.css";
import { DataContext } from "../../context/data-context";
import { appUrlMap } from "../../common";

const AppCarousel = () => {
  const { secretApy, secretTvl, junoApy, junoTvl, archApy, archTvl } =
    useContext(DataContext);
  return (
    <div id="app-carousel" className="carousel-outer-container">
      <div className="purple-gradient-heading component-heading colored-txt-blue">
        STAKE
      </div>
      <div className=" app-carousel-container">
        <AppCard
          name="Secret (New)"
          logoSrc={scrtLogo}
          description={
            "Introducing bSCRT. Now use bSCRT and seSCRT to earn additional yields and put your staked SCRT to work across the Secret ecosystem."
          }
          tvl={Number(secretTvl).toLocaleString() || "-"}
          apy={Number(secretApy).toLocaleString()}
          buttonName="Stake SCRT"
          appUrl={appUrlMap["SecretNew"]}
          new={true}
        />
        <AppCard
          name="Juno"
          logoSrc={junoLogo}
          description={
            "Stake your JUNO and receive seJUNO or bJUNO. Use your seJUNO, bJUNO to earn additional yields and put your staked JUNO to work across the Juno ecosystem."
          }
          tvl={Number(junoTvl).toLocaleString() || "-"}
          apy={Number(junoApy).toLocaleString()}
          buttonName="Stake JUNO"
          appUrl={appUrlMap["Juno"]}

          // comingSoon={true}
        />
        <AppCard
          name="Archway"
          logoSrc={archLogo}
          description={
            "Stake your ARCH and receive seARCH or bARCH. Now use seARCH and bARCH to earn additional yields and put your staked ARCH to work across the Archway ecosystem."
          }
          tvl={Number(archTvl) ? Number(archTvl).toLocaleString() : "-"}
          apy={Number(archApy) ? Number(archApy).toLocaleString() : "-"}
          buttonName="Stake ARCH"
          appUrl={appUrlMap["Archway"]}
          new={true}
          // comingSoon={true}
        />
        <AppCard
          name="Secret (Old)"
          logoSrc={scrtLogo}
          description={
            "Stake your SCRT and receive seSCRT. Use your seSCRT to earn additional yields and put your staked SCRT to work across the Secret ecosystem."
          }
          tvl={Number(secretTvl).toLocaleString() || "-"}
          apy={Number(secretApy).toLocaleString()}
          buttonName="Stake SCRT"
          appUrl={appUrlMap["SecretOld"]}
        />

        <AppCard
          name="Elrond"
          logoSrc={elrondLogo}
          description={
            "Staked assets are auto-compounded by our platform so you get higher staking returns without staking rewards manually."
          }
          tvl={"$5,533,933"}
          buttonName="Stake EGLD"
          comingSoon={true}
        />

        <AppCard
          name="Cosmos Hub"
          logoSrc={cosmos}
          description={
            "Staked assets are auto-compounded by our platform so you get higher staking returns without staking rewards manually."
          }
          tvl={"$5,533,933"}
          buttonName="Stake EGLD"
          comingSoon={true}
        />
        <AppCard
          name="Osmosis"
          logoSrc={osmosis}
          description={
            "Staked assets are auto-compounded by our platform so you get higher staking returns without staking rewards manually."
          }
          tvl={"$5,533,933"}
          buttonName="Stake JUNO"
          comingSoon={true}
        />
      </div>
    </div>
  );
};

export default AppCarousel;
