type SingleBackedByProps = {
	backedbyItem: any;
	link: string;
	backedbyTitle: string;
};

const SingleBackedBy = ({
	backedbyItem,
	link,
	backedbyTitle,
}: SingleBackedByProps) => {
	return (
		<div className='backedby__item'>
			<a href={link} target='_blank'>
				<img
					src={backedbyItem}
					alt='backedbyItem'
					className='backedby__item-img'
				/>
			</a>
			<h3>{backedbyTitle}</h3>
		</div>
	);
};

export default SingleBackedBy;
