type SingleEcoItemProps = {
	ecoImg: any;
	title: string;
	description: string;
	link: string;
};

const SingleEcoItem = ({
	ecoImg,
	title,
	description,
	link,
}: SingleEcoItemProps) => {
	return (
		<div className='eco__item'>
			<a href={link} target='_blank'>
				<img src={ecoImg} alt='ecoImg' className='eco__item-img' />
			</a>
			<div className='eco__info'>
				<h3>{title}</h3>
				<p>{description}</p>
			</div>
		</div>
	);
};

export default SingleEcoItem;
