import bigBrainImg from "../../common/images/backedby/bigbrain.jpg";
import cogitentImg from "../../common/images/backedby/cogitent.jpg";
// import backedby1 from "../../common/images/backedby/logo-white.svg";
import "./backedby.css";
import SingleBackedBy from "./SingleBackedBy";

const BackedBy = () => {
	return (
		<section id='backedby'>
			<div className='backedby__header'>
				<h2 className='purple-gradient-heading component-heading colored-txt-blue'>
					Backed By
				</h2>
				<p></p>
			</div>
			<div className='backedby__items'>
				<SingleBackedBy
					backedbyItem={bigBrainImg}
					link='https://www.bigbrain.holdings/'
					backedbyTitle='Big Brain Holding'
				/>
				<SingleBackedBy
					backedbyItem={cogitentImg}
					link='https://cogitent.ventures'
					backedbyTitle='CogiTent Ventures'
				/>
			</div>
		</section>
	);
};
export default BackedBy;
