import { useState } from "react";
import { roadmapMap } from "../../common";
import withWaypoint from "../../common/withWaypoint";
import "./roadmap.css";

const Roll = require("react-reveal/Roll");
const Zoom = require("react-reveal/Zoom");

function Roadmap() {
  const [visible, setVisible] = useState<string>("");

  // document.addEventListener('scroll',()=>{
  //   const roadMap = document.getElementById("roadmap")?.getBoundingClientRect();
  //  console.log(roadMap);

  // })
  // const roadMap = document.getElementById("roadmap")?.getBoundingClientRect().top;
  // console.log(roadMap);

  const handleVisible = (index: number) => {
    let detailWrapper = document.getElementsByClassName(
      `visible-${index}`
    ) as HTMLCollectionOf<HTMLElement>;
    for (let i in detailWrapper) {
      detailWrapper[i].style.visibility === "hidden"
        ? (detailWrapper[i].style.visibility = "visible")
        : (detailWrapper[i].style.visibility = "hidden");
    }
  };
  return (
    <div id="roadmap" className=" outer-container roadmap-wrapper">
      <Zoom>
        <div
          className={
            "component-heading purple-gradient-heading colored-txt-blue"
          }
        >
          ROADMAP
        </div>
      </Zoom>
      <div className="roadmap">
        <div className={"moving-bg"}></div>
        <div className="roadmap-upper">
          {/* <Zoom left cascade> */}
          {roadmapMap.map((item, index) => (
            <div className="roadmap-item">
              <div
                onClick={() => handleVisible(index)}
                className={"dots-wrapper"}
              >
                <div className="dots"></div>
              </div>
              <div className="title-wrapper">
                <div className="roadmap-title"> {item.title} </div>
              </div>
              {index % 2 === 0 ? (
                <div
                  className={`detail-wrapper visible-${index} detail-wrapper-even zoom-in-zoom-out`}
                >
                  <div className="arrow arrow-even"></div>
                  <div className="roadmap-detail"> {item.detail} </div>
                </div>
              ) : (
                <div
                  className={`detail-wrapper visible-${index} detail-wrapper-odd zoom-in-zoom-out`}
                >
                  <div className="arrow arrow-odd"></div>
                  <div className="roadmap-detail"> {item.detail} </div>
                </div>
              )}
            </div>
          ))}
          {/* </Zoom> */}
        </div>
      </div>
    </div>
  );
}
export default Roadmap;
//export default withWaypoint(Roadmap);

type Props = {
  fade: any;
};
