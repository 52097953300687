import coinhallImg from "../../common/images/ecosystem/coinhall.jpg";
import eco10 from "../../common/images/ecosystem/eco10.webp";
import eco2 from "../../common/images/ecosystem/eco2.jpg";
// import eco3 from "../../common/images/ecosystem/eco3.png";
import junoswapImg from "../../common/images/ecosystem/junoswap.jpg";
import osmoImg from "../../common/images/ecosystem/osmosis.png";
import siennaImg from "../../common/images/ecosystem/sienna.jpg";
import tfmImg from "../../common/images/ecosystem/tfm.jpg";

import "./ecosystem.css";
import SingleEcoItem from "./SingleEcoItem";

const Ecosystem = () => {
	return (
		<section id='eco'>
			<div className='eco__header'>
				<h2 className='purple-gradient-heading component-heading colored-txt-blue'>
					A Growing EcoSystem
				</h2>
				<p>
					StakeEasy empowers stakers to put their staked assets to use. Explore
					apps and services integrated with StakeEasy.
				</p>
			</div>
			<div className='eco__items'>
				<SingleEcoItem
					link='https://junoswap.com/'
					ecoImg={junoswapImg}
					title='JunoSwap'
					description='Flagship AMM on Juno network'
				/>
				<SingleEcoItem
					link='https://www.loop.markets/'
					ecoImg={eco2}
					title='Loop Finance DEX'
					description='A collection of DeFi and NFT premitives on Juno'
				/>
				{/* <SingleEcoItem
					link='https://polymer.fi/'
					ecoImg={eco4}
					title='Polymer'
					description='Stake your ETH to receive daily staking rewards'
				/> */}
				<SingleEcoItem
					link='https://osmosis.zone/'
					ecoImg={osmoImg}
					title='Osmosis DEX'
					description='The cosmos interchain DEX'
				/>
				{/* <SingleEcoItem
					link='https://crescent.network/'
					ecoImg={eco6}
					title='Crescent'
					description='Stake your ETH to receive daily staking rewards'
				/> */}
				<SingleEcoItem
					link='https://tfm.com/'
					ecoImg={tfmImg}
					title='TFM'
					description='DEX aggregator on Juno network'
				/>
				<SingleEcoItem
					link='https://coinhall.org/'
					ecoImg={coinhallImg}
					title='Coinhall'
					description='DEX aggregator on Juno network'
				/>
				<SingleEcoItem
					link='https://sienna.network/'
					ecoImg={siennaImg}
					title='Sienna Network'
					description='Set of privacy-preserving DeFi premitives on Secret network'
				/>
				{/* <SingleEcoItem
					link='https://secretswap.net/'
					ecoImg={eco9}
					title='Secretswap'
					description='Stake your ETH to receive daily staking rewards'
				/> */}
				<SingleEcoItem
					link='https://btn.group/secret_network/button_swap'
					ecoImg={eco10}
					title='ButtonSwap'
					description='DEX aggregator on Secret network'
				/>
			</div>
		</section>
	);
};
export default Ecosystem;
