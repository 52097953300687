import privacy from "./images/padlock.png";
import apy from "./images/apy.png";
import forget from "./images/forget.png";
import decentral from "./images/decentral.png";
import faucet from "./images/faucet.png";
import whitepaper from "../StakeEasy_Whitepaper.pdf";
import defi from "../common/images/defi.png";
import forgetit from "../common/images/forgetit.png";
import liq from "../common/images/LIQ.png";
import RISK from "../common/images/RISK_1.png";
import osmosis from "./images/osmosis-logo-main.svg";

export const API_URL = "https://secret-4.api.trivium.network:1317/";

export const navMenuMap: string[][] = [
  ["WhitePaper", whitepaper],
  // ["LitePaper", "#"],
  ["Blogs", "#blogs"],
];

export const menuItems = [
  {
    name: "WhitePaper",
    href: whitepaper,
  },
  {
    name: "Blogs",
    href: "https://stakeeasy.medium.com/",
  },
  {
    name: "Community",
    href: "",
    submenu: [
      {
        id: 1,
        name: "Twitter",
        href: " https://twitter.com/StakeEasy",
      },
      {
        id: 2,
        name: "Discord",
        href: "https://discord.gg/gKjzApDstD",
      },
      {
        id: 3,
        name: "Telegram",
        href: "https://t.me/StakeEasyProtocol",
      },
    ],
  },
  {
    name: "Learn",
    href: "",
    submenu: [
      {
        id: 1,
        name: "Documentation",
        href: "https://docs.stakeeasy.finance/",
      },
      {
        id: 2,
        name: "Validators",
        href: "https://app.stakeeasy.finance/info",
      },
      {
        id: 3,
        name: "Blog",
        href: "https://stakeeasy.medium.com/",
      },
    ],
  },
  {
    name: "About",
    href: "",
    submenu: [
      {
        id: 1,
        name: "Developers",
        href: "https://github.com/arufa-research/",
      },
      {
        id: 2,
        name: "Security",
        href: "https://app.stakeeasy.finance/static/media/security_report.5c038b84.pdf",
      },
      {
        id: 3,
        name: "Contact us",
        href: "https://docs.google.com/forms/d/138-d5SwsrPOoFRvL9JUAV7tSUmR1g7pDL57McSh70yE",
      },
    ],
  },
];

export const socialMediaMap: string[][] = [
  ["twitter", "https://twitter.com/StakeEasy"],
  ["discord", "https://discord.gg/gKjzApDstD"],
  ["medium", "https://stakeeasy.medium.com/"],
  // ["telegram-plane", "#"],
  ["github", "https://github.com/arufa-research"],
  ["reddit-alien", "https://www.reddit.com/r/stakeeasy/"],
];

export const featuresMap: string[][] = [
  [
    "Privacy First",
    privacy,
    "Our staking derivative token is a SNIP-20 token, so you can stake with privacy. Your staking information won't be visible to the outer world.",
  ],
  [
    "Compounded APY",
    apy,
    "Staked assets are auto-compounded by our platform so you get higher staking returns without staking rewards manually.",
  ],
  [
    "Stake and Forget",
    forget,
    "Our platform handles most of your manual work such as compounding, delegating, keeping track of validators.",
  ],
  [
    "Promotes Decentalization",
    decentral,
    "Our platform distributed the assets amongst multiple validators by choosing using a set of parameters to promote decentralization of assets and maximizing capital efficiency at the same time.",
  ],
  [
    "Liquid Staking",
    faucet,
    "Staking through our platform let's you have a staking derivative token which can be used in other DeFi protocols and can also be easily transferred or swapped with some other asset.",
  ],
];

export const featuresList = [
  {
    impHeading: "Tired of claiming",
    title: " your staking rewards every day?",
    description:
      "Staked assets are auto-compounded by our platform so you get higher staking returns without staking rewards manually.",
    imgSrc: forgetit,
  },
  {
    impHeading: "Can't decide",
    title: " in using tokens for DeFi or staking, what if you can do both?",
    description:
      "Staking through our platform let's you have a staking derivative token which can be used in other DeFi protocols and can also be easily transferred or swapped with some other asset.",
    imgSrc: defi,
  },
  {
    impHeading: "Want to unbond",
    title: " your staked asset immediately?",
    description:
      "Our platform distributed the assets amongst multiple validators by choosing using a set of parameters to promote decentralization of assets and maximizing capital efficiency at the same time.",
    imgSrc: liq,
  },
  {
    impHeading: "Want to provide liquidity",
    title: " without the risk of impermanent loss?",
    description:
      "Our platform handles most of your manual work such as compounding, delegating, keeping track of validators.",
    imgSrc: RISK,
  },
];

export const worksMap: string[][] = [
  ["Stake", "fas fa-shapes", "Stake your TOKEN and get seTOKEN."],
  ["Swap", "fas fa-exchange-alt", "Swap seTOKEN with TOKEN or USDC."],
  [
    "DeFi",
    "fas fa-hand-holding-usd",
    "Use seTOKEN to provide liquidity or use it as collateral.",
  ],
  // ["Private Governance", ""],
];

export const roadmapMap: Record<string, string>[] = [
  { title: " Q2 2022", detail: "DeFi Integration for seSCRT." },
  { title: " Early Q3 2022", detail: "Launch Staking on Juno Network." },
  {
    title: " Late Q3 2022",
    detail: "DeFi Integration of seJUNO (DEXes, Lending etc...).",
  },
  {
    title: " Q4 2022",
    detail: "Develop more utility for liquid staked assets",
  },
  {
    title: " Q4 2022",
    detail: "Interchain accounts enabled staking for cosmos.",
  },
];

export const appUrlMap = {
  SecretNew: "https://scrt.stakeeasy.finance/",
  SecretOld: "https://app.stakeeasy.finance/",
  Juno: "https://juno.stakeeasy.finance/",
  Archway: "https://archway.stakeeasy.finance/",
  Elrond: "",
  CosmosHub: "",
  Osmosis: "",
};
