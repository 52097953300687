import "./App.css";
import Header from "./components/header/header";
import Footer from "./components/footer/footer";
import Junction from "./components/junction/Junction";
import { DataContext } from "./context/data-context";
import { useData } from "./hooks/useData";

function App() {
  const data = useData();

  return (
    <div className="App">
      <DataContext.Provider value={data}>
        <Header/>
        <Junction />

        {/* <Header />
      <HomePage />
      <Features />
      <Works />
      <Roadmap />
      <Team />
    
      <Footer /> */}
      </DataContext.Provider>
    </div>
  );
}

export default App;
