import React, { useEffect, useState } from "react";
import "./features.css";
import { featuresMap, featuresList } from "../../common";
import withWaypoint from "../../common/withWaypoint";
import img1 from "../../common/images/defi.png";
import img2 from "../../common/images/RISK.png";
import img3 from "../../common/images/LIQ.png";
import img4 from "../../common/images/forgetit.png";

const Zoom = require("react-reveal/Zoom");
const Slide = require("react-reveal/Slide");
const Fade = require("react-reveal/Fade");
const Pulse = require("react-reveal/Pulse");

function Features(props: any) {
  const [isHeading, setIsHeading] = useState<boolean>(false);
  const [when, setWhen] = useState<boolean>(false);

  useEffect(() => {
    if (props.sectionTop < 0 && props.sectionTop > -3.5 * window.innerHeight) {
      if (props.headingTop < 50) {
        setIsHeading(true);
        console.log(props.headingTop, props.sectionTop);
      }
    } else {
      setIsHeading(false);
    }
  }, [props.headingTop, props.sectionTop]);

  const whenToShowHandler = (index: number): boolean => {
    if (
      props.sectionTop + (index + 1) * window.innerHeight <
        window.innerHeight / 2 &&
      props.sectionTop + (index + 1) * window.innerHeight >=
        (-1 * window.innerHeight) / 2
    )
      return true;
    else return false;
  };

  return (
    <>
      {/* <div id="features" className=" outer-container features-wrapper">
        <Zoom when={props.sectionTop < window.innerHeight / 2}>
          <div
            id="features-heading"
            className={
              isHeading
                ? "features-heading component-heading colored-txt-blue feature-heading-absolute"
                : "features-heading component-heading colored-txt-blue "
            }
          >
            FEATURES
          </div>
        </Zoom>
      </div> */}

      {featuresList.map((feature, index) => (
        <>
          <div
            id={"feature-" + index}
            className={`section feature-card-container ${"feature-" + index} ${
              index % 2 == 0 ? "row-reverse" : ""
            }`}
          >
            <Pulse>
              <div className="feature-image">
                <Zoom>
                  <img src={feature.imgSrc} />
                </Zoom>
              </div>
            </Pulse>
            <Slide right>
              <div className="features-card-wrapper">
                <div className="feature">
                  <div className="feature-card-header">
                    {/* <div className={"feature-icon-wrapper"}>
                    <img className={"feature-icon"} src={feature[1]} alt={`${feature[1]}`} />
                  </div> */}
                    <div className={"feature-title"}>
                      <span className="feature-title-impText">
                        {feature.impHeading.toUpperCase()}
                      </span>
                      {feature.title.toUpperCase()}{" "}
                    </div>
                  </div>

                  <div className={"feature-detail"}>
                    {" "}
                    {feature.description}{" "}
                  </div>
                </div>
              </div>
            </Slide>
            {/* <Slide left>
            <div className={"feature-icon-wrapper"}>
            <img className={"feature-icon"} src={feature[1]} alt={`${feature[1]}`} />
          </div>
            </Slide> */}
          </div>
        </>
      ))}
    </>
  );
}

export default Features;
//export default withWaypoint(Features);

type Props = {
  fade: any;
};
