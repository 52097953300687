import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay } from "@fortawesome/free-solid-svg-icons";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { faCalculator } from "@fortawesome/free-solid-svg-icons";
import rocket from "../../common/images/rocket.png";
import "./homePage.css";

const Fade = require("react-reveal/Fade");
const Zoom = require("react-reveal/Zoom");
const Flip = require("react-reveal/Flip");
const Slide = require("react-reveal/Slide");

function HomePage() {
  const [isPlayed, setIsPlayed] = useState(false);

  return (
    <div id="homePage" className="section home-wrapper">
      <div className="home-content-wrapper">
        {/* <Slide left cascade> */}

        {/* </Slide> */}
        <div className={"bg-wrapper"}>
          <div className="content-2-wrapper">
            <div className="home-content-2">
              <Zoom>
                <div className="coloured-text">
                  <span>SIMPLE |</span>
                  <span> QUICK |</span>
                  <span> EFFICIENT</span>
                </div>
              </Zoom>
            </div>
          </div>
        </div>
        <div className="home-content-subtitle">
          <Flip right>
            IBC enabled liquid staking protocol for the Cosmos ecosystem.
          </Flip>
        </div>
        {/* <div className={"home-content-1"}>
            <Zoom cascade >
              PRIVACY-PRESERVING LIQUID STAKING SOLUTION FOR SECRET NETWORK
              </Zoom>
      </div> */}
        <div className="homepage-btn-wrapper">
          <div className=" launch-homepage-btn">
            <a href="#app-carousel">
              <div className="stake-btn">
                Start your Journey{" "}
                <FontAwesomeIcon icon={faArrowRight} size="lg" />
              </div>
            </a>
          </div>
          <div className=" launch-homepage-btn">
            <a href="#calculator">
              <div className="secondary-btn stake-btn">
                Calculate Reward{"  "}
                <FontAwesomeIcon icon={faCalculator} size="1x" />
              </div>
            </a>
          </div>
        </div>
      </div>

      <div className="video-div-wrapper">
        {!isPlayed ? (
          <div className="iframe-wrapper">
            <iframe
              width="420"
              height="315"
              src="https://www.youtube.com/embed/Oscc9IUp-1Y?autoplay=0&mute=1&rel=0"
            ></iframe>
            <div className="video-description-wrapper">
              <h2>Arufa Research | StakeEasy</h2>
              Learn how StakeEasy makes your staking experience
              better
            </div>
            {/* <div
              className="close-icon-wrapper"
              onClick={() => setIsPlayed(false)}
            >
              <FontAwesomeIcon icon={faXmark} size="lg" />
            </div> */}
          </div>
        ) : (
          <div className="thumbnail-wrapper">
            <Zoom>
              <div
                onClick={() => setIsPlayed(true)}
                className="thumbnail-play-icon-wrapper"
              >
                <FontAwesomeIcon icon={faPlay} size="3x" />
              </div>
            </Zoom>
          </div>
        )}
        {/* <div className="hero-graphics">
          <img src={rocket} alt="rocket" />
        </div> */}
      </div>
    </div>
  );
}

export default HomePage;
//export default withWaypoint(HomePage);

type Props = {
  fade: any;
};
