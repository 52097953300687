import { createContext } from "react";

export const DataContext = createContext({
  secretApy: "",
  junoApy: "",
  junoTvl: "",
  secretTvl: "",
  secretPriceUsd: "",
  junoPriceUsd: "",
  archTvl: "",
  archApy: "",
  archPriceUsd: "",
});
