import { A11y, Autoplay, Scrollbar } from "swiper";
import "swiper/css";
import "swiper/css/autoplay";
import "./TweetSlide.css";

import { Swiper, SwiperSlide } from "swiper/react";
import twitter1 from "../../common/images/slider/twitter1.jpg";
import twitter2 from "../../common/images/slider/twitter2.png";
import twitter3 from "../../common/images/slider/twitter3.jpg";
import twitter4 from "../../common/images/slider/twitter4.jpg";
import twitter5 from "../../common/images/slider/twitter5.jpg";
import twitter6 from "../../common/images/slider/twitter6.jpg";

const TweetSlide = () => {
	return (
		<section id='twitterSlider'>
			<Swiper
				modules={[Scrollbar, A11y, Autoplay]}
				spaceBetween={50}
				slidesPerView={1}
				scrollbar={{ draggable: true }}
				onSwiper={(swiper) => console.log(swiper)}
				onSlideChange={() => console.log("slide change")}
				autoplay={true}
				centeredSlides
				loop
				breakpoints={{
					"320": {
						slidesPerView: 1,
						spaceBetween: 30,
					},
					"480": {
						slidesPerView: 1,
						spaceBetween: 30,
					},

					"768": {
						slidesPerView: 1,
						spaceBetween: 40,
					},

					"960": {
						slidesPerView: 3,
						spaceBetween: 40,
					},
				}}
			>
				<SwiperSlide>
					{({ isActive }) => (
						<a
							className={isActive ? `activeTweet` : `normalTweet`}
							target='_blank'
							href='https://twitter.com/terraspacesorg/status/1565839124875341824'
						>
							<section className='card'>
								<div className='card-header'>
									<img src={twitter1} alt='Twitter' className='card-img' />
									<div className='card-img-info'>
										<p className='card-name'>Gavin Andresen</p>
										<p className='card-username'>@gavinandresen</p>
									</div>
								</div>
								<div className='card-body'>
									<p>
										Here's the Juno Builders space hosted by{" "}
										<span className='card-special'>@rebel_defi</span> with{" "}
										<span className='card-special'>@tfm_com</span> chatting with{" "}
										<span className='card-special'>@StakeEasy</span>{" "}
										<span className='card-special'>@KevinGarrison</span>{" "}
										<span className='card-special'>@HighlanderCTs</span>{" "}
										<span className='card-special'>@Chinoman10_</span>{" "}
										<span className='card-special'>@VivekOn</span> and more!
										Recorded September 1st.
									</p>
									<p className='card-special'>
										https://terraspaces.org/2022/09/01/juno...
									</p>
									<p>
										Support TerraSpaces via{" "}
										<span className='card-special'>@SparkIBC</span>
									</p>
									<p>
										<span className='card-special'>https://sparkibc.zone</span>
									</p>
								</div>
								<div className='card-footer'>
									<p>5:08 AM Sep 3, 2022 Twitter Web App</p>
								</div>
							</section>
						</a>
					)}
				</SwiperSlide>
				<SwiperSlide>
					{({ isActive }) => (
						<a
							className={isActive ? `activeTweet` : `normalTweet`}
							target='_blank'
							href='https://twitter.com/SecretNetwork/status/1564962513288044546'
						>
							<section className='card'>
								<div className='card-header'>
									<img src={twitter2} alt='Twitter' className='card-img' />
									<div className='card-img-info'>
										<p className='card-name'>
											𝕊ecret Network - Shockwave is Here ⚡🌊
										</p>
										<p className='card-username'>@SecretNetwork</p>
									</div>
								</div>
								<div className='card-body'>
									<p>
										Did you catch yesterday's
										<span className='card-special'>#SCRTSpaces</span>?
									</p>
									<p>
										The <span className='card-special'>@StakeEasy</span> team
										shared some alpha, and presented upcoming features of their{" "}
										<span className='card-special'>#IBC </span>
										liquid staking protocol. Join us on Tuesdays at 4pm UTC /
										12pm ET for new episodes, and hit the link for the show!
									</p>
									<p>
										Listen:
										<span className='card-special'>
											https://youtu.be/Qt3U1uyi4Ss
										</span>
									</p>
								</div>
								<div className='card-footer'>
									<p>07:05 PM Aug 31, 2022 Loomly</p>
								</div>
							</section>
						</a>
					)}
				</SwiperSlide>
				<SwiperSlide>
					{({ isActive }) => (
						<a
							className={isActive ? `activeTweet` : `normalTweet`}
							target='_blank'
							href='https://twitter.com/loop_finance/status/1562799843545792512'
						>
							<section className='card'>
								<div className='card-header'>
									<img src={twitter3} alt='Twitter' className='card-img' />
									<div className='card-img-info'>
										<p className='card-name'>
											Loop Finance: DeFi + NFTs on Cosmos ⚛️
										</p>
										<p className='card-username'>@loop_finance</p>
									</div>
								</div>
								<div className='card-body'>
									<p>
										Very excited to be working with the{" "}
										<span className='card-special'>@StakeEasy</span> team to
										bring seJUNO and bJUNO pools to Juno!
									</p>

									<p>
										Help provide some liquidity and earn some{" "}
										<span className='card-special'>$LOOP</span>
										rewards while you're at it!
									</p>
									<p>
										<a href='https://t.co/qG0GOjVfPb'>
											<span className='card-special'>juno.loop.markets</span>
										</a>
									</p>
								</div>
								<div className='card-footer'>
									<p>07:51 AM Aug 25, 2022 Twitter Web App</p>
								</div>
							</section>
						</a>
					)}
				</SwiperSlide>
				<SwiperSlide>
					{({ isActive }) => (
						<a
							className={isActive ? `activeTweet` : `normalTweet`}
							target='_blank'
							href='https://twitter.com/StakeEasy/status/1554085698902589442'
						>
							<section className='card'>
								<div className='card-header'>
									<img src={twitter4} alt='Twitter' className='card-img' />
									<div className='card-img-info'>
										<p className='card-name'>StakeEasy</p>
										<p className='card-username'>@StakeEasy</p>
									</div>
								</div>
								<div className='card-body'>
									<p>
										StakeEasy has simplified the use of Lending & liquidity pool
										in DeFi.
									</p>

									<p>
										✨To make it smooth and less complex for the users, we have
										launched the DeFi page to give users a unified interface
										which is easier to use and understand.
									</p>
								</div>
								<div className='card-footer'>
									<p>06:44 AM Aug 1, 2022 Twitter Web App</p>
								</div>
							</section>
						</a>
					)}
				</SwiperSlide>
				<SwiperSlide>
					{({ isActive }) => (
						<a
							className={isActive ? `activeTweet` : `normalTweet`}
							target='_blank'
							href='https://twitter.com/orbital_command/status/1550888815908241414'
						>
							<section className='card'>
								<div className='card-header'>
									<img src={twitter5} alt='Twitter' className='card-img' />
									<div className='card-img-info'>
										<p className='card-name'>Orbital Command (🌎,🌔)</p>
										<p className='card-username'>@orbital_command</p>
									</div>
								</div>
								<div className='card-body'>
									<p>
										Over on <span className='card-special'>@SecretNetwork</span>{" "}
										the game of DeFi is booming 💥
									</p>

									<p>
										A new staking derivative provider{" "}
										<span className='card-special'>@StakeEasy</span> allows
										users to stake <span className='card-special'>$SCRT</span> ,
										earning 24.15% APY 🙌
									</p>

									<p>
										By staking with Stake Easy users receive a liquid token
										<span className='card-special'>$seSCRT</span> - like{" "}
										<span className='card-special'>$cLUNA</span>
										or <span className='card-special'>$LUNAX</span>
										🔥
									</p>
								</div>
								<div className='card-footer'>
									<p>11:01 AM Jul 23, 2022 Twitter Web App</p>
								</div>
							</section>
						</a>
					)}
				</SwiperSlide>
				<SwiperSlide>
					{({ isActive }) => (
						<a
							className={isActive ? `activeTweet` : `normalTweet`}
							target='_blank'
							href='https://twitter.com/rebel_defi/status/1549999964570456064'
						>
							<section className='card'>
								<div className='card-header'>
									<img src={twitter6} alt='Twitter' className='card-img' />
									<div className='card-img-info'>
										<p className='card-name'>Rebel Defi 🌖</p>
										<p className='card-username'>@@rebel_defi</p>
									</div>
								</div>
								<div className='card-body'>
									<p>
										Reminds me of good ol' Terra{" "}
										<span className='card-special'>$LUNA</span> days 🙌
									</p>

									<p>
										Playing the DeFi game on @SecretNetwork with{" "}
										<span className='card-special'>$SCRT</span> 🕵️
									</p>

									<p>
										Using <span className='card-special'>@StakeEasy</span>
										's <span className='card-special'>$seSCRT</span>
										to earn auto-compounding 24% APY 🔥
									</p>
									<p>
										Depositing on{" "}
										<span className='card-special'>@sienna_network</span> for a
										further 31% APY paid in{" "}
										<span className='card-special'>$SIENNA</span> 🔥
									</p>
								</div>
								<div className='card-footer'>
									<p>12:09 AM Jul 21, 2022 Twitter Web App</p>
								</div>
							</section>
						</a>
					)}
				</SwiperSlide>
			</Swiper>
		</section>
	);
};

export default TweetSlide;
