// import validatorItem from "../../common/images/validator-item.svg";
import validator1 from "../../common/images/validator/validator1.png";
import validator2 from "../../common/images/validator/validator2.svg";
import validator3 from "../../common/images/validator/validator3.png";
import validator4 from "../../common/images/validator/validator4.png";
import validator5 from "../../common/images/validator/validator5.png";
import validator6 from "../../common/images/validator/validator6.png";
import validator7 from "../../common/images/validator/validator7.png";
import SingleValidator from "./SingleValidator";
import "./validators.css";

const Validators = () => {
	return (
		<section id='validator'>
			<div className='validator__header'>
				<h2 className='purple-gradient-heading component-heading colored-txt-blue'>
					Validators
				</h2>
				<p>Explore validators</p>
			</div>
			<div className='validator__items'>
				<SingleValidator
					validatorTitle='NodeVine'
					validatorItem={validator1}
					link='https://www.mintscan.io/secret/validators/secretvaloper1nc2justrp64paes6vrkk5atqxheqkjnl5sc76q'
				/>
				<SingleValidator
					validatorTitle='SCG Ventures'
					validatorItem={validator2}
					link='https://www.mintscan.io/secret/validators/secretvaloper1qjk5uduu3zg356chmstcp2eqgdn35jp3ztxh4x'
				/>
				<SingleValidator
					validatorTitle='Artemis Red'
					validatorItem={validator3}
					link='https://www.mintscan.io/secret/validators/secretvaloper1jjlfeshltdy6ngnf5tg6aeaygsvf0kxg8wck04'
				/>
				<SingleValidator
					validatorTitle='DomeriumLabs'
					validatorItem={validator4}
					link='https://www.mintscan.io/secret/validators/secretvaloper1jmd0h669t0dg9hz2yesuy80ahq0wm8segghg4y'
				/>
				<SingleValidator
					validatorTitle='Lavender.Five Nodes'
					validatorItem={validator5}
					link='https://www.mintscan.io/secret/validators/secretvaloper1t5wtcuwjkdct9qkw2h6m48zu2hectpd6ulmekk'
				/>
				<SingleValidator
					validatorTitle='Highlander | ChainTools'
					validatorItem={validator6}
					link='https://www.mintscan.io/juno/validators/junovaloper1dru5985k4n5q369rxeqfdsjl8ezutch8mc6nx9'
				/>
				<SingleValidator
					validatorTitle='Disperze'
					validatorItem={validator7}
					link='https://www.mintscan.io/juno/validators/junovaloper194v8uwee2fvs2s8fa5k7j03ktwc87h5ym39jfv'
				/>
			</div>
		</section>
	);
};
export default Validators;
