import { useEffect, useRef, useState } from "react";

import Dropdown from "./Dropdown";
import "./Navbar.css";
const MenuItems = ({ items, depthLevel }) => {
	const [dropdown, setDropdown] = useState(false);

	let ref = useRef();

	useEffect(() => {
		const handler = (event) => {
			if (dropdown && ref.current && !ref.current.contains(event.target)) {
				setDropdown(false);
			}
		};
		document.addEventListener("mousedown", handler);
		document.addEventListener("touchstart", handler);
		return () => {
			// Cleanup the event listener
			document.removeEventListener("mousedown", handler);
			document.removeEventListener("touchstart", handler);
		};
	}, [dropdown]);

	const onMouseEnter = () => {
		window.innerWidth > 960 && setDropdown(true);
	};

	const onMouseLeave = () => {
		window.innerWidth > 960 && setDropdown(false);
	};

	return (
		<li
			className='menu-items'
			ref={ref}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
		>
			{items.submenu ? (
				<>
					<button
						className='menuButton'
						type='button'
						aria-haspopup='menu'
						aria-expanded={dropdown ? "true" : "false"}
						onClick={() => setDropdown((prev) => !prev)}
					>
						{items.name} <i className='fas fa-caret-down' />
					</button>
					<Dropdown
						depthLevel={depthLevel}
						submenus={items.submenu}
						dropdown={dropdown}
					/>
				</>
			) : (
				<a href={items.href}>{items.name}</a>
			)}
		</li>
	);
};

export default MenuItems;
