/* type HeaderProps = {
  FontAwesomeIconProps.icon: IconProp
} */
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { menuItems } from "../../common";
import logo from "../../common/images/flatwhite.png";
import logoText from "../../common/images/logoText.png";
import MenuItems from "../Navigation/MenuItems";
import "./header.css";

function Header() {
	const [show, setShow] = useState(false);

	const toggleMenu = () => {
		setShow(!show);
	};

	return (
		<>
			{show && <div className='backdrop' onClick={() => setShow(false)}></div>}
			<div className='header-wrapper'>
				<div className='header-child'>
					<a href='#homePage'>
						<div className='logo-wrapper'>
							<img className='logoImage' src={logo} alt='Stake Easy' />
							<img className='logoText' src={logoText} alt='Stake Easy' />
						</div>
					</a>

					{/* <div className="nav-menu">
          {navMenuMap.map((menuItem) => (
            <>
              <a href={menuItem[1]} className="nav-menu-item">
                {" "}
                {menuItem[0]}{" "}
              </a>
            </>
          ))}
        </div> */}
				</div>

				<div className='center-menu nav-menu '>
					<ul className='menus'>
						{menuItems.map((menu, index) => {
							const depthLevel = 0;
							return (
								<MenuItems items={menu} key={index} depthLevel={depthLevel} />
							);
						})}
					</ul>
				</div>

				{show && (
					<div className='mobile-center-menu mobile-nav-menu'>
						<ul className='mobile-menus'>
							{menuItems.map((menu, index) => {
								const depthLevel = 0;
								return (
									<MenuItems items={menu} key={index} depthLevel={depthLevel} />
								);
							})}
						</ul>
					</div>
				)}

				<div className='header-child'>
					{/* <div className="social-media-wrapper">
          {socialMediaMap.map((social) => (
            <a href={social[1]} target="_blank">
              <i className={`fab fa-${social[0]}`}></i>
            </a>
          ))}
        </div> */}

					<a href='#app-carousel'>
						<button className='stake-btn' onClick={toggleMenu}>
							Get Started
						</button>
					</a>
				</div>

				<FontAwesomeIcon
					icon={faBars}
					className='menuBar'
					onClick={toggleMenu}
				/>
			</div>
		</>
	);
}

export default Header;
