import { useState } from "react";
import AppCarousel from "../app-carousel/AppCarousel";
import BackedBy from "../backedby/BackedBy";
import Calculator from "../calculator/Calculator";
import Ecosystem from "../ecosystem/ecosystem";
import Features from "../features/feature";
import Footer from "../footer/footer";
import HomePage from "../homePage/homePage";
import Roadmap from "../roadmap/roadmap";
import TweetSlide from "../slider/TweetSlide";
import Team from "../team/team";
import Validators from "../validators/validators";
import Works from "../working/works";
import "./Junction.css";
const Junction = () => {
	const [featureHeadingPosition, setFeatureHeadingPosition] = useState<any>();
	const [featuresPosition, setFeaturesPosition] = useState<any>();
	const [worksPostion, setWorksPosition] = useState<number>();
	const handleScroll = () => {
		const featureHeading = document
			.getElementById("features-heading")
			?.getBoundingClientRect().top;
		const featureTop = document
			.getElementById("features")
			?.getBoundingClientRect().top;
		const worksTop = document
			.getElementById("working")
			?.getBoundingClientRect().top;
		setFeatureHeadingPosition(featureHeading);
		setFeaturesPosition(featureTop);
		setWorksPosition(worksTop);
		// console.log(featureHeadingPosition);

		//  console.log(document.getElementById("homePage")?.getBoundingClientRect().top)
		//  console.log(document.getElementById("features")?.getBoundingClientRect().top)
		//  console.log(document.getElementById("feature-0")?.getBoundingClientRect().top)
		//  console.log(document.getElementById("feature-1")?.getBoundingClientRect().top)
		//  console.log(document.getElementById("feature-2")?.getBoundingClientRect().top)
		//  console.log(document.getElementById("feature-3")?.getBoundingClientRect().top)
		//  console.log(document.getElementById("feature-4")?.getBoundingClientRect().top)
		//  console.log(document.getElementById("working")?.getBoundingClientRect().top)
		//  console.log(document.getElementById("roadmap")?.getBoundingClientRect().top)
		//  console.log(document.getElementById("team")?.getBoundingClientRect().top)
		//  console.log("scrolled");
	};

	return (
		<div onScroll={handleScroll} className='junction-container'>
			{/* <Header /> */}
			<HomePage />
			<AppCarousel />
			<Ecosystem />

			<Features
				headingTop={featureHeadingPosition}
				sectionTop={featuresPosition}
			/>
			<Works sectionTop={worksPostion} />
			{/* <Calculator /> */}
			<Roadmap />
			<Validators />
			<BackedBy />
			<Team />
			{/* <Blogs /> */}

			<TweetSlide />

			<Footer />
		</div>
	);
};

export default Junction;
