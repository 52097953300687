import React, { useState, useEffect } from "react";
import "./works.css";
import { worksMap } from "../../common";
import withWaypoint from "../../common/withWaypoint";
const Zoom = require("react-reveal/Zoom");
const Slide = require("react-reveal/Slide");

function Works(props: any) {
  const [when, setWhen] = useState<Boolean>(false);

  useEffect(() => {
    if (
      props.sectionTop < window.innerHeight / 2 &&
      props.sectionTop > (-1 * window.innerHeight) / 2
    )
      setWhen(true);
    else setWhen(false);
  }, [props.sectionTop]);

  //     const workPosition = document.getElementById("working")?.getBoundingClientRect();
  //  console.log(workPosition);

  return (
    <div id="working" className=" section outer-container works-wrapper">
      <Slide left>
        <div className={"works-heading component-heading flex"}>
          HOW IT WORKS?
        </div>
      </Slide>
      <Zoom right cascade>
        <div className="works">
          {worksMap.map((work) => (
            <div className={"red-outline"}>
              <div className="work-card-wrapper">
                <div className={"circles"}>
                  <i className={work[1]}></i>
                </div>
                <div className={"work-card"}>
                  <div className="work-title"> {work[0]} </div>
                  <div className="work-detail"> {work[2]} </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </Zoom>
    </div>
  );
}
export default Works;
//export default withWaypoint(Works);

type Props = {
  fade: any;
};
