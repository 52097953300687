import React, { useContext } from "react";
import "./AppCard.css";
import scrtLogo from "../../../common/images/scrt.png";
import { DataContext } from "../../../context/data-context";

const AppCard = (props: any) => {
  return (
    <div className="app-card-container">
      <div className="app-card-header">
        <div className="app-card-logo">
          <img src={props.logoSrc} />
        </div>
        <div className="app-card-name">{props.name}</div>
      </div>
      <div className="app-card-body">
        <div className="app-card-description">{props.description}</div>
        <div className="app-card-info">
          {!props.comingSoon && (
            <>
              <div className="app-card-tvl">
                <span className="app-card-info-value">
                  <span
                    style={{ color: "white" }}
                    className="app-card-info-name"
                  >
                    {"$ "}
                  </span>
                  {props.tvl}
                </span>
                <span className="app-card-info-name">TVL</span>
              </div>
              <div className="app-card-tvl">
                <span className="app-card-info-value">{props.apy} %</span>
                <span className="app-card-info-name">APY</span>
              </div>
            </>
          )}
        </div>
      </div>
      <div className="app-card-footer ">
        {props.comingSoon ? (
          <div>Coming Soon</div>
        ) : (
          <button
            onClick={() => window.open(props.appUrl)}
            className="stake-btn"
          >
            {props.buttonName}
          </button>
        )}
      </div>
      {props.new && (
        <div className="card-new-tag">
          <p>NEW</p>
        </div>
      )}
    </div>
  );
};

export default AppCard;
